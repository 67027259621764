<template>
  <v-container
    class="pt-0"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <v-card
          style="width: 100%; height: 100%"
          class="pa-2"
        >
          <v-card-title
            class="text-h3 font-weight-bold grey--text mt-2 pb-0 ml-4"
          >
            Search Through All Equipment
          </v-card-title>
          <div
            class="my-6 mx-6 px-16"
          >
            <div
              class="my-4"
            >
              <div class="mb-3">
                Enter serial number and/or asset tag:
              </div>
              <div class="d-flex flex-wrap">
                <v-text-field
                  v-model="stateSerial"
                  class="mx-3"
                  width="45%"
                  dense
                  outlined
                  label="Serial"
                  type="text"
                />
                <v-text-field
                  v-model="stateAssetTag"
                  class="mx-3"
                  width="45%"
                  dense
                  outlined
                  label="Asset Tag"
                  type="text"
                />
              </div>
              <div
                style="width: 100%"
                class="d-flex justify-center"
              >
                <v-btn
                  id="openSetLocationDialogButton"
                  :disabled="!stateAssetTag && !stateSerial"
                  :loading="searchingEquipment"
                  class="font-weight-bold"
                  color="primary"
                  @click="getEquipmentBySerialOrAssetTag"
                >
                  Search
                </v-btn>
              </div>
            </div>
          </div>
          <v-data-table
            v-if="searchResults.length > 0"
            :headers="headers"
            single-select
            item-key="id"
            :loading="searchingEquipment"
            loading-text="Searching for equipment"
            :items="searchResults"
            :fixed-header="true"
            calculate-widths
            @click:row="
              (item, row) => {
                row.select(true)
                selectedTableItem = item
              }
            "
          >
            <template
              v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>
          </v-data-table>
          <div
            style="width: 100%"
            class="d-flex justify-center mb-6"
          >
            <v-btn
              v-if="searchResults.length > 0"
              :disabled="!selectedTableItem"
              :loading="checkingPendingEdits"
              class="font-weight-bold"
              color="primary"
              @click="navigateToEditEquipment"
            >
              Edit
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col
        v-if="showAlert"
        class="px-3 pb-0"
        cols="12"
      >
        <v-alert
          :type="alertType"
          class="mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showEditWarningDialog"
      max-width="400"
    >
      <v-card class="py-4 pl-6">
        <v-card-title class="text-h5 pl-0">
          Warning:
        </v-card-title>
        <div
          class="d-flex pr-6"
        >
          This equipment is currently being edited by another user. Please complete the Pending Edit request before making further changes to the equipment.
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="showEditWarningDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import common from '@/api/common.js'
  import ewcApi from '@/api/ewc.js'
  import moment from 'moment'

  export default {
    name: 'EWCSearch',
    data: () => ({
      stateSerial: '',
      stateAssetTag: '',

      searchingEquipment: false,
      searchResults: [],
      selectedTableItem: null,

      showAlert: false,
      alertType: 'info',
      alertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
      alertMessageNoResults: 'We were unable to find equipment matching the provided information.',

      checkingPendingEdits: false,
      showEditWarningDialog: false,
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Label',
            value: 'equipmentEwc.label',
            width: '150',
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
          },
          {
            text: 'Site',
            value: 'siteName',
            width: '150',
          },
          {
            text: 'Floorplan',
            value: 'gridName',
            width: '150',
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
          },
        ]
      },
    },
    created () {
    },
    methods: {
      getEquipmentBySerialOrAssetTag () {
        this.searchingEquipment = true
        this.searchResults = []
        this.selectedTableItem = null
        let skipFinallyBlock = false
        ewcApi.getEquipmentBySerialOrAssetTag(this.stateSerial, this.stateAssetTag)
          .then(result => {
            console.log(result)

            this.searchResults = result.data.map(data => {
              return {
                ...data.equipmentEwc,
                gridId: data.gridId,
                gridName: data.gridName,
                siteId: data.siteId,
                siteName: data.siteName,
              }
            })
            console.log(this.searchResults)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getEquipmentBySerialOrAssetTag()
            } else {
              this.showAlert = true
              if (error.response?.status === 404) {
                this.alertType = 'info'
                this.alertMessage = this.alertMessageNoResults
              } else {
                this.alertType = 'error'
                this.alertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.searchingEquipment = false
            }
          })
      },

      navigateToEditEquipment () {
        this.checkingPendingEdits = true
        this.showAlert = false
        let skipFinallyBlock = false
        ewcApi.canEditEquipment(this.selectedTableItem.id)
          .then(response => {
            console.log(response)
            this.$router.push({ path: `/ewc/edit/${this.selectedTableItem.id}/${this.selectedTableItem.gridId}/${this.selectedTableItem.siteId}/` })
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.navigateToEditEquipment()
            } else {
              if (error.response?.status === 409) {
                this.showEditWarningDialog = true
              } else {
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => { if (!skipFinallyBlock) { this.checkingPendingEdits = false } })
      },

    },
  }
</script>
